import React, { useEffect, useRef, useState } from 'react'
import { Button, Header, Input } from '../../components'
import { BsPlusCircleFill, BsTrash } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import axiosClient from '../../axios-client';
import Loading from '../../components/Loading';
import { useStateContext } from '../../contexts/ContextProvider';
import { AiTwotoneEdit } from 'react-icons/ai';
import ActionButton from '../../components/ActionButton';
import { MdEditNote, MdOutlineAccountBalanceWallet } from "react-icons/md";
import { IoIosSearch } from 'react-icons/io';
import Select from 'react-select';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { FaEdit, FaEye, FaPrint, FaRegFileExcel } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import AddExamen from './AddExamen';
import Alert from '../../components/Alert';
import EditExamen from './EditExamen';
import VoirExamen from './VoirExamen';
import TabHead from '../../components/TabHead';
import MySelect from '../../components/MySelect';
import FicheNoteExamen from './FicheNoteExamen';

const Examens = () => {
  const navigate = useNavigate();
  const [examens, setExamens] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const { setToken, user } = useStateContext();

  const [classes, setClasses] = useState([]);
  const [selectedClasse, setSelectedClasse] = useState(null);
  const [inputValueClasse, setValueClasse] = useState('');

  const [trimestres, setTrimestres] = useState([]);
  const [selectedTrimestre, setSelectedTrimestre] = useState(null);
  const [inputValueTrimestre, setValueTrimestre] = useState('');

  const [scolaires, setScolaires] = useState([]);
  const [selectedScolaire, setSelectedScolaire] = useState(null);
  const [inputValueScolaire, setValueScolaire] = useState('');

  const [selectedExamen, setSelectedExamen] = useState(null);
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const [showView, setShowView] = useState(false);
  const [showFiche, setShowFiche] = useState(false);


  const tableRef = useRef(null);
  const [req, setReq] = useState({
    classe: '',
    scolaire: '',
    trimestre: '',
  });

  const closeAdd = () => {
    setShowAdd(false);
  }
  const closeEdit = () => {
    setShowEdit(false);
    setSelectedExamen(null);

  }
  const closeFiche = () => {
    setShowFiche(false);
    setSelectedExamen(null);
  }

  const getClasses = () => {
    axiosClient.get('/classes')
      .then((data) => {
        setClasses(data.data.classes);
      }).catch(err => {
        const response = err.response;
        if (err.response.status === 401) {
          setToken(null);
        } else {
          if (response.status === 401) {
            setToken(null);
          }
          else {
            if (response.status === 403) {
              navigate('/403');
            } else {
              if (response.status === 404) {
                navigate('/404');
              }
              else {
                navigate('/500');
              }
            }
          }
        }
      })
  }
  const handleInputChangeClasse = value => {
    setValueClasse(value);
  };

  // handle selection


  // handle selection
  const handleChangeClasse = value => {
    setSelectedClasse(value);
    setReq({ ...req, classe: value.id });
  }

  const handleChangeTrimestre = value => {
    setSelectedTrimestre(value);
    setReq({ ...req, trimestre: value.id });
  }

  const handleInputChangeTrimestre = value => {
    setValueTrimestre(value);
  };

  const handleChangeScolaire = value => {
    setSelectedScolaire(value);
    setReq({ ...req, scolaire: value.id });
  }

  const handleInputChangeScolaire = value => {
    setValueScolaire(value);
  };

  const getTrimestres = () => {
    axiosClient.get('/trimestres')
      .then((data) => {
        setTrimestres(data.data.trimestres);
      }).catch(err => {
        navigate('/500');
      })
  }

  const getScolaires = () => {
    axiosClient.get('/getScolaires')
      .then((data) => {
        setScolaires(data.data.scolaires);
      }).catch(err => {
        navigate('/500');
      })
  }
  const getExamens = () => {
    setLoading(true);
    axiosClient.get('/examens?scolaire=' + req.scolaire + '&classe=' + req.classe + '&trimestre=' + req.trimestre)
      .then((data) => {
        setExamens(data.data.examens);
        setLoading(false);
      }).catch(err => {
        const response = err.response;
        if (err.response.status === 401) {
          setToken(null);
        } else {
          if (response.status === 422) {
            setErrors(response.data.errors);

          }
          else {
            if (response.status === 403) {
              navigate('/403');
            } else {
              if (response.status === 404) {
                navigate('/404');
              }
              else {
                navigate('/500');
              }
            }
          }
        }
        setLoading(false);
      })

  }

  const supprimer = (examen) => {
    Swal.fire({
      title: "Confirmation",
      text: "Etes vous sûre de supprimer cette element ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2196F3",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui!",
      cancelButtonText: "Non!"
    }).then((result) => {
      if (result.isConfirmed) {
        axiosClient.delete('/examen/' + examen.id)
          .then(response => {
            toast.success('Opération effectuée avec succès!', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            getExamens();
          }).catch(err => {
            const response = err.response;
            if (err.response.status === 401) {
              setToken(null);
            } else {
              if (response.status === 422) {
                setErrors(response.data.errors);
              }
              else {
                if (response.status === 403) {
                  navigate('/403');
                } else {
                  if (response.status === 404) {
                    navigate('/404');
                  }
                  else {
                    navigate('/500');
                  }
                }
              }
            }

          })
      }
    });
  }

  const closeShow = () => {
    setShowView(false);
    setSelectedExamen(null);
  }

  useEffect(() => {
    getClasses();
    getTrimestres();
    getScolaires();
  }, []);
  return (
    <>
      <Header icon={<MdEditNote />} category='Page' title='Examens' titleAr={"الإمتحانات"} />
      {errors &&
        Object.keys(errors).map(key => (
          <Alert key={key} message={errors[key][0]} />
        ))
      }
      <div className='flex-col '>
        <div className='flex flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap justify-between'>
          {
            user.role && (user.role.name === 'dretude' || user.role.name === 'admin') ?

              <div className='items-end mt-4'>
                <Button onClick={() => { setShowAdd(true) }} color="bg-blue-500" icon={<BsPlusCircleFill />} size="20" text="Ajouter" textColor="text-white" />
              </div>
              : <></>
          }

          <div className="flex flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap gap-2">
            <MySelect label="Scolaire" labelAr="السنة الدراسية" placeholder="Scolaire..." cacheOptions defaultOptions value={selectedScolaire} getOptionLabel={e => e.code} getOptionValue={e => e.id} options={scolaires} onInputChange={handleInputChangeScolaire} onChange={handleChangeScolaire} />
            <MySelect label="Classe" labelAr="القسم" placeholder="Classe..." cacheOptions defaultOptions value={selectedClasse} getOptionLabel={e => e.libelle} getOptionValue={e => e.id} options={classes} onInputChange={handleInputChangeClasse} onChange={handleChangeClasse} />
            <MySelect label="Trimestre" labelAr="الفصل" placeholder="Trimestre..." cacheOptions defaultOptions value={selectedTrimestre} getOptionLabel={e => e.code} getOptionValue={e => e.id} options={trimestres} onInputChange={handleInputChangeTrimestre} onChange={handleChangeTrimestre} />
            <div className='mt-8'>
              <Button onClick={() => getExamens()} color="bg-yellow-500" icon={<IoIosSearch />} size="20" text="Chercher" textColor="text-white" />
            </div>
            <div className="mt-8">
              <DownloadTableExcel
                filename="Inscriptions"
                sheet="inscriptions"
                currentTableRef={tableRef.current}
              >
                <Button color="bg-green-500" icon={<FaRegFileExcel />} size="20" text="Exporter" textColor="text-white" />

              </DownloadTableExcel>
            </div>
          </div>
        </div>

        <div className='mt-3 w-full overflow-x-auto'>
          <table ref={tableRef} className='table-auto'>
            <thead>
              <tr>
                <th><TabHead left="Classe" right="القسم" /></th>
                <th><TabHead left="Date" right="التاريخ" /></th>
                <th><TabHead left="Matière" right="المادة" /></th>
                <th><TabHead left="Heure Debut" right="بداية الوقت" /> </th>
                <th><TabHead left="Heure Fin" right="نهاية الوقت" /> </th>
                <th><TabHead left="Enseignant" right="المصحح" /></th>
                <th><TabHead left="Actions" right={"إجراء"}/></th>
              </tr>
            </thead>
            {loading ? <tbody><tr className='content-center items-center'><td className='content-center items-center' colSpan="9"><Loading /></td></tr></tbody> :
              (

                <tbody>
                  {examens && examens.map(o => (
                    <tr className={(o.color === 'gr' ? 'bg-green-300' : (o.color === 'yl' ? 'bg-yellow-200' : ''))}>
                      <td>{o.classe.libelle}</td>
                      <td>{o.dateExamen}</td>
                      <td>{o.matiere.libelle}</td>
                      <td>{o.heureDebut}</td>
                      <td>{o.heureFin}</td>
                      <td>{o.enseignant.nom}</td>
                      <td>
                        <div className="flex justify-center w-full items-center gap-2">
                        <ActionButton onClick={() => {
                            setSelectedExamen(o);
                            setShowFiche(true);
                          }} color="bg-gray-500" icon={<FaPrint />} textColor="text-white" title="Fiche des notes" />
                          <ActionButton onClick={() => {
                            setSelectedExamen(o);
                            setShowView(true);
                          }} color="bg-green-500" icon={<FaEye />} textColor="text-white" title="Voir" />
                          <ActionButton onClick={() => {
                            setSelectedExamen(o);
                            setShowEdit(true);
                          }} color="bg-blue-500" icon={<FaEdit />} textColor="text-white" title="Modifier" />
                          <ActionButton onClick={() => supprimer(o)} color="bg-red-500" icon={<BsTrash />} textColor="text-white" title="Annuler" />
                        </div>
                      </td>
                    </tr>
                  ))

                  }
                </tbody>
              )
            }
          </table>
        </div>
        {
          showAdd ? <AddExamen onClose={closeAdd} /> : <></>
        }
        {
          showEdit ? <EditExamen selectedExamen={selectedExamen} onClose={closeEdit} /> : <></>
        }
        {
          showView ? <VoirExamen selectedExamen={selectedExamen} onClose={closeShow} /> : <></>
        }
        {
          showFiche ? <FicheNoteExamen selectedExamen={selectedExamen} onClose={closeFiche} /> : <></>
        }
      </div>
    </>
  )
}

export default Examens