import React, { useRef, useState } from 'react';
import { Button, Header, Input } from '../../components';
import Loading from '../../components/Loading';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import axiosClient from '../../axios-client';
import { FaPrint, FaRegFileAlt } from 'react-icons/fa';
import Icon from '../../data/icon.png';
import BulletinElement from '../../components/BulletinElement';
import ReactToPrint from 'react-to-print';
import { GiCancel } from 'react-icons/gi';
import { toast } from 'react-toastify';
import { GrPowerReset } from 'react-icons/gr';
import Entete from '../../components/Entete';

const ShowBulletinSecondaire = ({ selectedBulletin, onClose }) => {
    const [loading, setLoading] = useState(false);

    const { token, setToken, user } = useStateContext();

    const bulletinPrintRef = useRef();
    const [bulletinSecondaire, setBulletinSecondaire] = useState({
        lignes: [],
        rang: '',
        matricule: '',
        eleveFr: '',
        eleveAr: '',
        moyenne: '',
        total: '',
        classe: '',
        trimestreAr: '',
        trimestreFr: '',
        mention: '',
        decision: '',
        devoirs: '',
        scolaire: '',

    });

    const getPrint = () => {
        setLoading(true);
        axiosClient.get('/imprimerBulletinSecondaire/' + selectedBulletin.id)
            .then((data) => {
                setBulletinSecondaire({
                    ...bulletinSecondaire, lignes: data.data.lignes, matricule: data.data.matricule,
                    rang: data.data.rang, eleveFr: data.data.eleveFr,
                    eleveAr: data.data.eleveAr, moyenne: data.data.moyenne,
                    total: data.data.total, classe: data.data.classe,
                    trimestreAr: data.data.trimestreAr, trimestreFr: data.data.trimestreFr,
                    mention: data.data.mention, decision: data.data.decision, scolaire: data.data.scolaire,

                });
                setLoading(false);

            }).catch(err => {
                const response = err.response;
                if (err.response.status === 401) {
                    setToken(null);
                } else {
                    if (response.status === 401) {
                        setToken(null);
                    }
                    else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }
                }

            })
    }

    const recalculer = () => {
        setLoading(true);
        axiosClient.get('/recalculerBulletinSecondaire/' + selectedBulletin.id)
            .then((data) => {
                setLoading(false);
                toast.success('Opération effectuée avec succès!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });

            }).catch(err => {
                const response = err.response;
                if (err.response.status === 401) {
                    setToken(null);
                } else {
                    if (response.status === 401) {
                        setToken(null);
                    }
                    else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }
                }

            })
    }

    const navigate = useNavigate();



    useEffect(() => {
        getPrint();
    }, []);
    return (
        <>
            <div className=' fixed inset-0  h-full bg-black bg-opacity-25 backdrop-blur-sm  '>
                <div className='h-screen w-full flex justify-center items-center'>
                    <div className='flex-col w-full max-h-screen max-w-screen-xl orien overflow-auto bg-white lg:p-7 xl:p-7  p-2 md:p-7 rounded-xl'>
                        {loading ? <Loading />
                            :
                            <div className=''>
                                <Header icon={<FaRegFileAlt />} title="Bulletin Secondaire" titleAr="كشف الدرجات"/>
                                <div className="flex-col">
                                    <div className="flex w-full justify-end items-end gap-2">
                                        <ReactToPrint
                                            trigger={() => {
                                                return <Button icon={<FaPrint />} color="ml-1 bg-green-500" textColor="text-white" text="Imprimer" />
                                            }}
                                            content={() => bulletinPrintRef.current}
                                            documentTitle='Bulletin'
                                            pageStyle='bulletin'
                                        />

                                        <Button icon={<GiCancel />} onClick={() => onClose()} color="bg-red-500" textColor="text-white" text="Fermer" />
                                    </div>
                                    <div ref={bulletinPrintRef} className="flex-col relative">
                                        <Entete />
                                        <div className="flex w-full gap-1 justify-center items-center ">
                                            <div className="flex-col font-bold w-full">
                                                <div className="flex w-full justify-center items-center">
                                                    <h2 >   كشف درجات امتـــحــــــــان {bulletinSecondaire.trimestreAr}</h2>
                                                </div>
                                                <div className="flex w-full justify-center items-center">
                                                    <h2>Bulletin des notes de la Composition {bulletinSecondaire.trimestreFr}</h2>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="flex font-bold gap-3 justify-between my-2">
                                            <BulletinElement left="Matricule: " center={bulletinSecondaire.matricule} />
                                            
                                            <BulletinElement left="Nom: " center={bulletinSecondaire.eleveFr} />
                                            {bulletinSecondaire.eleveAr !== null ?
                                                <BulletinElement left="الإسم الكامل :" center={bulletinSecondaire.eleveAr} />
                                                :
                                                <></>

                                            }
                                            <BulletinElement left="Classe: " center={bulletinSecondaire.classe} />
                                        </div>

                                        <div className='my-2 w-full overflow-x-auto '>

                                            <table className='border border-black'>
                                                <thead>
                                                    <tr >
                                                        <th className='bg-gray-200 border w-1/5 border-black'>
                                                            <div className="flex justify-between w-full">
                                                                <span>
                                                                    Observation
                                                                </span>
                                                                <span>
                                                                    المـلاحــظــــة
                                                                </span>
                                                            </div>
                                                        </th>
                                                        <th className='bg-gray-200 border w-1/6 border-black'>
                                                            <div className="flex justify-between w-full">
                                                                <span>
                                                                    Note
                                                                </span>
                                                                <span>
                                                                    النتــيــجـة
                                                                </span>
                                                            </div>
                                                        </th>
                                                        <th className='bg-gray-200 border border-black'>
                                                            <div className="flex justify-between w-full">
                                                                <span>
                                                                    Les devoirs
                                                                </span>
                                                                <span>
                                                                    الإختـبـارات
                                                                </span>
                                                            </div>
                                                        </th>
                                                        <th className='bg-gray-200 border w-1/6 border-black'>
                                                            <div className="flex justify-between w-full">
                                                                <span>
                                                                    Coefficient
                                                                </span>
                                                                <span>
                                                                    الضــارب
                                                                </span>
                                                            </div>
                                                        </th>
                                                        <th className='bg-gray-200 border border-black'>
                                                            <div className="flex justify-between w-full">
                                                                <span>
                                                                    Matiere
                                                                </span>
                                                                <span>
                                                                    المــادة
                                                                </span>
                                                            </div>
                                                        </th>

                                                    </tr>
                                                </thead>
                                                {loading ? <tbody><tr className='content-center items-center'><td className='content-center items-center' colSpan="9"><Loading /></td></tr></tbody> :
                                                    (

                                                        <tbody className='border border-black'>
                                                            <div className="flex w-full opacity-25 absolute">
                                                                <img className=" mx-auto h-72 w-auto" src={Icon} alt="Your Company" />
                                                            </div>
                                                            {bulletinSecondaire.lignes && bulletinSecondaire.lignes.map(o => (
                                                                <>
                                                                    <tr className='font-semibold text-right ' >
                                                                        <td className='border border-black'></td>
                                                                        <td className='border border-black'>{o.note}/{o.noteMax}</td>
                                                                        <td className='border border-black' >{o.devoirs}</td>
                                                                        <td className='border border-black' >{o.coefficient}</td>
                                                                        <td className='border border-black' >{o.libelle}</td>
                                                                    </tr>
                                                                </>
                                                            ))
                                                            }
                                                        </tbody>
                                                    )
                                                }
                                            </table>
                                        </div>
                                        <div className="flex flex-wrap gap-3 justify-between my-2">
                                            <BulletinElement left="Decision: " center={bulletinSecondaire.decision} right=":القــــرار" />
                                            <BulletinElement left="Rang: " center={bulletinSecondaire.rang} right=":الرتـــبـــة" />
                                            <BulletinElement left="Moyenne general: " center={bulletinSecondaire.moyenne} right=":المعدل العام" />
                                            <BulletinElement left="Total: " center={bulletinSecondaire.total} right=":المجـمـوع" />
                                            <BulletinElement left="Mention: " center={bulletinSecondaire.mention} right=":التــقــديــر" />
                                        </div>
                                        <div className="flex w-full my-2 gap-1 justify-start items-start ">
                                            <div className="flex-col ">
                                                <div className="flex font-bold  justify-center items-center">
                                                    <h5>ختم و توقيع المدير العام</h5>
                                                </div>
                                                <div className="flex font-bold  justify-center items-center">
                                                    <h5>Signature et Cachet du Directeur Général</h5>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="flex w-full font-bold  gap-1 justify-center items-center ">
                                            <h3><u>Année scolaire: {bulletinSecondaire.scolaire} : السنـــة الدراسيـــة</u></h3>
                                        </div>
                                    </div>
                                </div>


                            </div>

                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default ShowBulletinSecondaire